<template>
  <PageTemplate>
		<Grid>
			<GridContainer size="12">
				<Stack border="b">
					<Section>
						<Heading size="1" uppercase>Windermere</Heading>
					</Section>
				</Stack>
			</GridContainer>
		</Grid>
		<Grid>
			<GridContainer size="12">
				<Stack width="100%" border="b">
					<Stack width="100%" justify="spaceBetween">
						<Link url="/portfolio/sedona" display="inline">
							<Stack height="60px" width="70px" justify="center" border="r">
								<Icon icon="arrow_left" size="large" color="tertiary" />
							</Stack>
						</Link>
						<Link url="/portfolio/dallas" display="inline">
							<Stack height="60px" width="70px" justify="center" border="l">
								<Icon icon="arrow_right" size="large" color="tertiary" />
							</Stack>
						</Link>
					</Stack>
				</Stack>
			</GridContainer>
		</Grid>
		<Grid>
			<GridContainer size="12 6@md">
				<Stack direction="column" align="left" width="100%" border="n r@md">
					<Section>
						<Phone image="theme-ar/windermere1.jpg" />
					</Section>
					<Stack width="100%" border="b"></Stack>
					<Section size="large">
						<Stack direction="column" space="1">
							<Images width="100%" image="theme-ar/windermere2.jpg" align="top" border />
						</Stack>
					</Section>
				</Stack>
			</GridContainer>
			<GridContainer size="12 6@md">
				<Stack direction="column" align="left" width="100%" border="t n@md">
					<Stack width="100%">
						<Section>
							<Heading size="2">Institutional, but creative and harmonious.</Heading>
							<Paragraph>The goal was to create an immersive website accessible to as many people as possible. The content being visually rich, it was necessary to optimize the experience to accompany the visitor with any device.</Paragraph>
							<Paragraph>The first objective was to have a design and a user experience designed to highlight the brand. The design thus plays on cool greens, marble accents, and warm images to stick to the atmosphere of the latter.</Paragraph>
							<Paragraph>The second objective was to make an easy and obvious link between the content, the visuals and the accessibility. The overall experience of the site is based on a balance between these three points.</Paragraph>
							<Button url="/windermere" label="GO TO WEBSITE" color="tertiary" newTab />
						</Section>
					</Stack>
					<Stack width="100%" border="b"></Stack>
					<Section>
						<Browser image="theme-ar/windermere3.jpg" />
					</Section>
				</Stack>
			</GridContainer>
		</Grid>
		<Grid>
			<GridContainer size="12">
				<Stack border="b"></Stack>
			</GridContainer>
		</Grid>
		<Grid>
			<GridContainer size="12 4@md">
				<Stack width="100%">
					<Section>
						<Heading size="2" margin="none" uppercase>Design Elements</Heading>
					</Section>
				</Stack>
			</GridContainer>
			<GridContainer size="12 4@md">
				<Stack direction="column" align="left" width="100%" height="100%" border="t l@md">
					<Stack width="100%" border="b">
						<Section>
							<Heading size="5" margin="none" uppercase>Colors</Heading>
						</Section>
					</Stack>
					<Section>
						<Stack direction="column" align="left" width="100%" space="1">
							<Color color="#5E9E95" title="Brand Primary"></Color>
							<Color color="#31645E" title="Brand Secondary"></Color>
							<Color color="#E1E1E1" title="Brand Tertiary"></Color>
						</Stack>
					</Section>
				</Stack>
			</GridContainer>
			<GridContainer size="12 4@md">
				<Stack direction="column" align="left" justify="spaceBetween" width="100%" height="100%" border="t l@md">
					<Stack width="100%" border="b">
						<Section>
							<Heading size="5" margin="none" uppercase>Typography</Heading>
						</Section>
					</Stack>
					<Section>
						<Stack direction="column" align="left" width="100%" space="1">
							<Stage theme="theme-wm"><Heading size="2" margin="none">Heading</Heading></Stage>
						</Stack>
					</Section>
					<Stack width="100%" border="n b@md"></Stack>
					<Section>
						<Stack direction="column" align="left" width="100%" space="1">
							<Stage theme="theme-wm"><Heading size="3" margin="none">Accent</Heading></Stage>
						</Stack>
					</Section>
					<Stack width="100%" border="n b@md"></Stack>
					<Section>
						<Stack direction="column" align="left" width="100%" space="1">
							<Stage theme="theme-wm"><Heading size="5" margin="none">Sub Header</Heading></Stage>
						</Stack>
					</Section>
					<Stack width="100%" border="n b@md"></Stack>
					<Section>
						<Stack direction="column" align="left" width="100%" space="1">
							<Stage theme="theme-wm"><Paragraph margin="none">Body Copy</Paragraph></Stage>
						</Stack>
					</Section>
					<Stack width="100%"></Stack>
				</Stack>
			</GridContainer>
		</Grid>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/annarobbins/template/Page'; 
export default {
  components: {
		PageTemplate
  },
	props: {
		theme: String
	},
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  },
}

</script>

<style lang="scss">
</style>
